let rocketButton = document.getElementById('bt-rocket-mo');
let starterButton = document.getElementById('bt-starter-mo');
let founderButton = document.getElementById('bt-founder-mo');
let thePlan = document.querySelectorAll('.rocket-yr');
let arrayButton = [rocketButton, starterButton, founderButton];



for (let currentButton of arrayButton) {
    currentButton.onmouseover = function () { 
        for(let x of thePlan) {
        //testClass = x.classList.contains('gelatine');
       // if (testClass === false) {
            

            x.classList.add('gelatine');
        
        //}
       // else
       // setTimeout(x.classList.remove('gelatine'), 1000);
        
        }
    }
};
